<template>
  <div class="video-tutorials--details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Video Details</h2>
          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveVideoTutorial"
            :loading="form.$busy"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <video-details-form :form-data="form" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VideoDetailsForm from '../components/VideoDetailsForm'
import Video from '@/models/Video'
import Form from '@/utils/form'

import { mdiArrowRight, mdiCheck } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import { map, extend } from 'lodash'

export default {
  name: 'VideoDetails',

  components: {
    VideoDetailsForm,
  },

  data() {
    return {
      form: new Form({
        id: this.$attrs.id,
        tags: [],
        equipments: [],
        programs: [],

        title: null,
        difficulty: null,
        description: null,
        is_exercise: false,
        is_restricted_video: false,
        video_public_id: null,
        image_public_id: null,
      }),

      icons: {
        check: mdiCheck,
        next: mdiArrowRight,
      },
    }
  },

  created() {
    if (this.$attrs.id) {
      this.getVideo()
    }
  },

  methods: {
    ...mapActions({
      saveVideo: 'videos/saveVideo',
    }),

    ...mapMutations({
      insertVideo: 'videos/insertVideo',
      setSelectedVideo: 'videos/setSelectedVideo',
    }),

    async getVideo() {
      let { data } = await Video.include([
        'categories.ancestors',
        'equipments',
        'programs',
        'tags',
      ]).find(this.$attrs.id)

      if (data) {
        this.setSelectedVideo(data)

        this.form = extend(this.form, data)
      }
    },

    async saveVideoTutorial() {
      this.form.$busy = true

      let programs = map(this.form.programs, (program) => program.id)
      let equipments = map(this.form.equipments, (equipment) => {
        return typeof equipment === 'object' ? equipment.id : equipment
      })

      await this.saveVideo({ ...this.form, programs, equipments })
        .then((data) => {
          this.form.$timeout(() => {
            this.insertVideo(data)

            this.$router.push({
              name: 'video.programs',
              params: { id: data.id },
            })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },
  },
}
</script>
