<template>
  <v-select
    v-bind="$attrs"
    v-model="difficulty"
    :items="difficulties"
    @change="onChange"
    item-text="name"
    item-value="id"
  />
</template>

<script>
export default {
  name: 'DifficultyLevel',

  props: {
    value: [String, Number],
  },

  data() {
    return {
      difficulty: this.value,
      difficulties: [
        { id: 1, name: 'Beginner' },
        { id: 2, name: 'Intermediate' },
        { id: 3, name: 'Advanced' },
      ],
    }
  },

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
