<template>
  <div class="bbr-video--contents-details-form">
    <v-row>
      <v-col cols="12" :sm="4">
        <v-checkbox
          v-model="form.is_exercise"
          label="Is Exercise"
          color="primary"
        />
      </v-col>
      <v-col cols="12" :sm="4">
        <v-checkbox
          v-model="form.is_restricted_video"
          label="Restricted Video"
          color="primary"
        />
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="12" sm="4">
        <!-- <v-text-field
          v-model="form.duration"
          label="Video Duration (minutes/readonly)"
          readonly
          outlined
          flat
        /> -->
      </v-col>

      <v-col cols="12" :sm="form.is_exercise ? 8 : 12">
        <v-text-field
          v-model="form.title"
          label="Video Title"
          :error-messages="form.$getError('title')"
          outlined
          flat
        />
      </v-col>

      <v-col v-if="form.is_exercise" cols="12" sm="4">
        <difficulty-level
          v-model="form.difficulty"
          placeholder="Difficulty"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12">
        <v-combobox
          v-model="form.tags"
          :hide-no-data="!search"
          :search-input.sync="search"
          label="Video Tags"
          hide-selected
          small-chips
          multiple
          outlined
          flat
        >
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading">Create</span>
              <v-chip class="mx-2" color="primary" small>
                {{ search }}
              </v-chip>
              <span> as a new tag </span>
              <span> . Press <kbd>enter</kbd> to create a new one </span>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>

      <v-col v-if="form.is_exercise" cols="12">
        <search-equipment-chips
          v-model="form.equipments"
          label="Exercise Video Equipments"
          :error-messages="form.$getError('equipments')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="form.video_public_id"
          label="Public Video ID"
          :error-messages="form.$getError('video_public_id')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="form.image_public_id"
          label="Public Video Image ID"
          :error-messages="form.$getError('image_public_id')"
          outlined
          flat
        />
      </v-col>

      <!-- <v-col cols="12">
        <video-programs
          v-model="form.programs"
          label="Video Programs"
          placeholder="Search for Programs and Challenge"
          return-object
          hide-selected
          multiple
          outlined
          flat
        />
      </v-col> -->

      <v-col cols="12">
        <v-textarea
          v-model="form.description"
          class="pb-12"
          label="Video Description"
          :error-messages="form.$getError('description')"
          auto-grow
          outlined
          flat
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchEquipmentChips from '@/components/elements/equipments/SearchEquipmentChips'
import DifficultyLevel from '@/components/elements/DifficultyLevel'
import Form from '@/utils/form'

export default {
  name: 'VideoDetailsForm',

  components: {
    SearchEquipmentChips,
    DifficultyLevel,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      search: null,
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    'form.is_exercise'(value) {
      if (!value) {
        this.form.difficulty = null
      }
    },
  },
}
</script>
