<template>
  <div class="exercises-search-chips">
    <v-autocomplete
      v-model="equipments"
      v-bind="$attrs"
      :error-messages="errors"
      :hide-no-data="!hasSearch"
      :items="selectedEquipments"
      :search-input.sync="search"
      :label="label"
      ref="equipment"
      item-text="name"
      item-value="id"
      return-object
      hide-selected
      multiple
    >
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular
            v-if="loading"
            size="24"
            color="primary"
            indeterminate
          />
        </v-fade-transition>
      </template>

      <template v-slot:selection="data">
        <v-chip
          class="mt-3 text-capitalize"
          v-bind="data.attrs"
          @click:close="removeEquipment(data.index)"
          close
        >
          {{ data.item.name }}
        </v-chip>
      </template>

      <template v-if="!alreadySelected || isExactSearch" v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-center">
              <p class="text-truncate grey--text text--darken-2">
                No results matching
                <strong class="text-capitlized"> "{{ search }}" </strong>.
              </p>

              <v-btn class="primary" @click="addNewEquipment" block text>
                Add
                <strong class="secondary--text text--lighten-2">
                  "{{ search }}"
                </strong>
                Equipment
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import Equipment from '@/models/Equipment'

import { uniqBy, debounce } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchEquipmentChips',

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: 'Search equipments',
    },

    errorMessages: {
      type: Array,
    },
  },

  data() {
    return {
      search: '',
      errors: [],
      loading: false,
      isMounted: false,

      equipments: [],
      searchedEquipements: [],
    }
  },

  computed: {
    ...mapGetters({
      exerciseEquipments: 'exercises/getSelectedExerciseEquipments',
    }),

    selectedEquipments() {
      return uniqBy([...this.searchedEquipements, ...this.equipments], 'id')
    },

    hasSearch() {
      return !!this.search
    },

    alreadySelected() {
      if (!this.isMounted) {
        return
      }

      return !!this.$refs.equipment.filteredItems.length
    },

    isExactSearch() {
      return this.searchedEquipements.length === 1
    },
  },

  created() {
    this.equipments = this.value
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    searchEquipements: debounce(function() {
      this.fetchSearchedEquipments().then(({ data }) => {
        this.searchedEquipements = data
      })
    }, 250),

    async fetchSearchedEquipments() {
      let query = await Equipment.where('search', this.search).params({
        limit: 100,
      })

      return query.get()
    },

    async addNewEquipment() {
      let equipment = new Equipment({ name: this.search }).save()

      equipment.then(({ data }) => {
        this.search = null
        this.equipments.push(data)
      })
    },

    removeEquipment(index) {
      this.equipments.splice(index, 1)
    },
  },

  watch: {
    search(val) {
      if (val) {
        return this.searchEquipements()
      }
    },

    equipments(values) {
      this.$emit('input', values)
    },

    value(values) {
      this.equipments = values
    },

    errorMessages(errors) {
      this.errors = errors
    },
  },
}
</script>
